import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image, Button } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Home | Welcome to PixelPioneers Marketing Agency</title>
        <meta
          name={"description"}
          content={"Crafting Your Digital Footprint, One Pixel at a Time"}
        />
        <meta
          property={"og:title"}
          content={"Home | Welcome to PixelPioneers Marketing Agency"}
        />
        <meta
          property={"og:description"}
          content={"Crafting Your Digital Footprint, One Pixel at a Time"}
        />
        <meta
          property={"og:image"}
          content={"https://zopicosold.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://zopicosold.com/img/5225530.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://zopicosold.com/img/5225530.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://zopicosold.com/img/5225530.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://zopicosold.com/img/5225530.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://zopicosold.com/img/5225530.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://zopicosold.com/img/5225530.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="150px 0 150px 0"
        min-height="70vh"
        background="linear-gradient(180deg,rgba(247, 251, 255, 0.8) 0%,rgba(255, 255, 255, 0.5) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://zopicosold.com/img/1.jpg) 50% 50% /cover no-repeat scroll padding-box"
        lg-padding="90px 0 90px 0"
        quarkly-title="404-2"
      >
        <Override
          slot="SectionContent"
          max-width="1220px"
          justify-content="flex-start"
        />
        <Box
          align-items="center"
          display="flex"
          justify-content="center"
          flex-direction="column"
        >
          <Text
            color="--dark"
            margin="8px 0px 16px 0px"
            sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
            font="--headline1"
            letter-spacing="-0.025em"
            lg-margin="0px 0px 16px 0px"
          >
            PixelPioneers
          </Text>
          <Text
            lg-width="80%"
            font="--lead"
            color="--dark"
            margin="0px 0px 36px 0px"
            text-align="center"
            lg-margin="0px 0px 24px 0px"
          >
            Welcome to PixelPioneers, where your brand's journey begins! As your
            partners in the digital world, we are dedicated to crafting bespoke
            marketing strategies that resonate with your audience and drive
            results. Our approach is simple yet effective, focusing on what
            truly matters – your success.
          </Text>
        </Box>
      </Section>
      <Section padding="90px 0 100px 0" quarkly-title="Price-18">
        <Override slot="SectionContent" />
        <Text
          margin="0px 0px 20px 0px"
          text-align="center"
          font="normal 500 46px/1.2 --fontFamily-sans"
          color="--darkL2"
        >
          Why Choose Us?
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(4, 1fr)"
          grid-gap="0 34px"
          lg-grid-template-columns="1fr"
          lg-grid-gap="35px 0"
        >
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="column"
            align-items="center"
            background="--color-light"
            border-radius="25px"
            justify-content="space-between"
          >
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              align-items="center"
            >
              <Image
                src="https://zopicosold.com/img/2.jpg"
                display="block"
                width="100%"
                height="300px"
                object-fit="cover"
                border-radius="25px 25px 0 0"
              />
              <Box
                min-width="100px"
                min-height="100px"
                margin="0px 0px 25px 0px"
                display="flex"
                flex-direction="column"
                align-items="center"
                padding="10px 25px 0px 25px"
              >
                <Text
                  margin="10px 0px 10px 0px"
                  color="--darkL1"
                  text-align="center"
                  font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
                >
                  Tailored Strategies
                </Text>
                <Text
                  margin="0px 0px 0px 0px"
                  color="--darkL1"
                  font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
                  text-align="center"
                >
                  Each campaign is crafted with your unique goals in mind,
                  ensuring personalized attention and bespoke solutions.
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="column"
            align-items="center"
            background="--color-light"
            border-radius="25px"
            justify-content="space-between"
          >
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              align-items="center"
            >
              <Image
                src="https://zopicosold.com/img/3.jpg"
                display="block"
                width="100%"
                height="300px"
                object-fit="cover"
                border-radius="25px 25px 0 0"
              />
              <Box
                min-width="100px"
                min-height="100px"
                margin="0px 0px 25px 0px"
                display="flex"
                flex-direction="column"
                align-items="center"
                padding="10px 25px 0px 25px"
              >
                <Text
                  margin="10px 0px 10px 0px"
                  color="--darkL1"
                  text-align="center"
                  font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
                >
                  Data-Driven Decisions
                </Text>
                <Text
                  margin="0px 0px 0px 0px"
                  color="--darkL1"
                  font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
                  text-align="center"
                >
                  Leveraging the latest in analytics and trends, we make
                  decisions that are informed, strategic, and effective.
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="column"
            align-items="center"
            background="--color-light"
            border-radius="25px"
            justify-content="space-between"
          >
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              align-items="center"
            >
              <Image
                src="https://zopicosold.com/img/4.jpg"
                display="block"
                width="100%"
                height="300px"
                object-fit="cover"
                border-radius="25px 25px 0 0"
              />
              <Box
                min-width="100px"
                min-height="100px"
                margin="0px 0px 25px 0px"
                display="flex"
                flex-direction="column"
                align-items="center"
                padding="10px 25px 0px 25px"
              >
                <Text
                  margin="10px 0px 10px 0px"
                  color="--darkL1"
                  text-align="center"
                  font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
                >
                  Creative Excellence
                </Text>
                <Text
                  margin="0px 0px 0px 0px"
                  color="--darkL1"
                  font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
                  text-align="center"
                >
                  Our creative team thrives on innovation, delivering
                  eye-catching designs and content that captures hearts and
                  minds.
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="column"
            align-items="center"
            background="--color-light"
            border-radius="25px"
            justify-content="space-between"
          >
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              align-items="center"
            >
              <Image
                src="https://zopicosold.com/img/5.jpg"
                display="block"
                width="100%"
                height="300px"
                object-fit="cover"
                border-radius="25px 25px 0 0"
              />
              <Box
                min-width="100px"
                min-height="100px"
                margin="0px 0px 25px 0px"
                display="flex"
                flex-direction="column"
                align-items="center"
                padding="10px 25px 0px 25px"
              >
                <Text
                  margin="10px 0px 10px 0px"
                  color="--darkL1"
                  text-align="center"
                  font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
                >
                  Result-Oriented
                </Text>
                <Text
                  margin="0px 0px 0px 0px"
                  color="--darkL1"
                  font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
                  text-align="center"
                >
                  At the end of the day, your success is our success. We're
                  committed to delivering measurable results that impact your
                  bottom line.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="50px 0 80px 0"
        md-padding="80px 0 80px 0"
        sm-padding="50px 0 50px 0"
        quarkly-title="CTA/Lead/Form-3"
      >
        <Box
          min-width="100px"
          min-height="100px"
          padding="80px 80px 80px 80px"
          border-radius="40px"
          md-padding="50px 50px 50px 50px"
          sm-padding="50px 30px 50px 30px"
          background="--color-dark"
        >
          <Text
            margin="0px 0px 20px 0px"
            color="--light"
            text-align="center"
            font="normal 700 38px/1.2 --fontFamily-sans"
            lg-margin="0px 0 20px 0"
            sm-font="normal 700 36px/1.2 --fontFamily-sans"
            sm-text-align="left"
          >
            Who We Are
          </Text>
          <Text
            margin="0px 40px 50px 40px"
            text-align="center"
            font="--lead"
            color="rgba(255, 255, 255, 0.8)"
            lg-margin="0px 0 50px 0"
            sm-text-align="left"
          >
            At PixelPioneers, we believe in the power of creativity, data-driven
            strategies, and personalized marketing solutions. Our team is
            composed of seasoned marketing professionals, creative minds, and
            tech enthusiasts, all united by a passion for innovation and
            excellence. We specialize in turning your vision into reality,
            making sure your brand stands out in the digital landscape.
          </Text>
          <Box
            min-width="10px"
            min-height="10px"
            display="flex"
            justify-content="center"
            sm-flex-direction="column"
            sm-align-items="center"
          >
            <Button
              background="rgba(247, 251, 255, 0)"
              href="/contacts"
              type="link"
              text-decoration-line="initial"
              font="normal 400 17px/1.5 --fontFamily-sans"
            >
              Contact Us &gt;&gt;
            </Button>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
